import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import EnterCode from './components/EnterCode/EnterCode'
import Apply from './components/Apply/Apply'
import Digest from './components/Digest/Digest'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EnterCode />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/digest" element={<Digest />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
