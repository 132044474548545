import './EnterCode.css';
import logo from './../../logo.png';
import { useState } from "react";
import { useNavigate } from "react-router-dom";



export default function EnterCode() {

  // const handleSubmit = event => {
  //    event.preventDefault();
  //    alert('Invalid code')
  //  }
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://api.peregrine.vip/apply?c="+code, {
        method: "GET",
      });
      let resJson = await res.json();
      setCode("");
      if (res.status === 200) {
        if (resJson.message === "valid code"){
          navigate("/apply", {state: {"eid": resJson.eid}});
        }
        else if (resJson.message === "inactive code") {
          setMessage("Inactive code");
        }
        else {
          setMessage("Invalid code");
        }
      } else {
        setMessage("API error");
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div>
      <div className="Module Heading-module">
        <h1>P E R E G R I N E</h1>
        {/*<h4 className="Slogan">fine dining, extempore</h4>*/}
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <div className="Module Info-module">
        <p>Peregrine membership is invite-only.<br></br>Enter your invitation code below.</p>
      </div>
      <p><span className="Filler">-</span><span className="Error-msg">{message}</span></p>
      <form onSubmit={handleSubmit}>
        <div className="Codebox">
          <input
            className="Textbox"
            placeholder="Enter code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button className="Enter-btn">></button>
        </div>
      </form>
      <br/>
    </div>
  );
}
