import './Digest.css';
import logo from './../../logo.png';
import { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate, useSearchParams } from "react-router-dom";


import Card from './Card';
import Confirm from './Confirm';

export default function Digest() {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [resys, setResys] = useState("");
  const [mem, setMem] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const p = searchParams.get("p");

  const [modal, setModal] = useState(false);
  const [active, setActive] = useState("");

  let handleSubmit = async (e, resy) => {
    e.preventDefault();
    setActive(resy);
    setModal(true);
  };

  let handleCancel = async (e) => {
    e.preventDefault();
    setModal(false);
  }

  let handleConfirm = async (e) => {
    e.preventDefault();
    setModal(false);
    let resy = active;
    try {
       let res = await fetch("https://api.peregrine.vip/digest?p="+p+"&r="+resy.id, {
          method: "POST",
        });
        let resJson = await res.json();
        if (res.status === 200) {
          //navigate("/digest?p="+p);
          if (resJson.redirect === null){
            alert("Congrats! You're on the books for "+resy.restaurant+". You'll receive a confirmation email shortly.");
            window.location.reload(false);
          }
          else{
            alert("Congrats! You're on the books for "+resy.restaurant+". You'll receive a confirmation email shortly. In the meantime, you will be directed to an optional payment page where you can pay the amount you think is fair.");
            window.location.replace(resJson.redirect);
          }
        }
        else if (res.status === 404) {
          alert("Unfortunately, this seating has already been claimed by another member and is no longer available.");
          window.location.reload(false);
        }
        else {
          console.log(res.status);
          window.location.reload(false);
        }
    } catch (err) {
      console.log(err);
    }
  }

  let handleLoad = async (e) => {
    //e.preventDefault();
    try {
      let res = await fetch("https://api.peregrine.vip/digest?p="+p, {
        method: "GET",
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setResys(resJson.reservations.sort((a, b) => (new Date(a.datetime) - new Date(b.datetime))));
        setMem(resJson.member);
      }
      else {
        setMessage("URL not assoicated with active digest");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleLoad();
    const timer = setInterval(handleLoad, 30000);
    return () => clearInterval(timer);
  }, [])


  if (p != null) {
    if (p.length === 28){
      return(
        <div>
          {mem &&
            <div>
              <h1> Hi, {mem.firstName}</h1>
              <p>here is this week's availability</p>
            </div>
          }
          <Confirm show={modal} onCancel={handleCancel} onConfirm={handleConfirm} resy={active} />
          <span className="Error-msg">{message}</span>
          <div className="Card-container">
            {
              resys && resys.length>0 && resys.map((item, i)=>
                  <Card key={i} resData={item} book={handleSubmit}/>
              )
            }
          </div>
        </div>
      );
    }
    else{return(<Navigate to="/" replace={true} />);}
  }
  else {
    return(
      <Navigate to="/" replace={true} />
    );
  }
}
