import { Component } from 'react'
import './Card.css';

export default class Card extends Component{
  render() {
    var res = this.props.resData;
    return(
      <div className={res.available? "Card Res-avail" : "Card Res-claimed"}>
        <h3 className="Res-restaurant">{res.restaurant}</h3>
        <h4 className="Res-seats">table for {res.seats}</h4>
        <h5 className="Res-time">{res.weekday} {res.date} at {res.time}</h5>
        <div className="Button-container">
          <button
              className={res.available ? "Button Res-avail" : "Button Res-claimed"}
              onClick={event => this.props.book(event, res)}>
            {res.available ? "Claim now" : "Claimed"}
          </button>
        </div>
        <p className="Res-description">{res.description}</p>
      </div>
    );
  }
}

      // <div key={i} className={item.available ? "Resy-avail" : "Resy-claimed"}>
      //   <h3>{item.restaurant}</h3>
      //     <h5>table for {item.seats}</h5>
      //   <h5>on {item.weekday} {item.date}</h5>
      //   <h5>{item.time}</h5>
      //   {
      //     item.available &&
      //       <div className="Button-container">
      //         <button className="Book-btn" onClick={event => handleSubmit(event, item)}>Claim now {item.price===0 ? "" : "for $"+item.price}</button>
      //       </div>
      //   }
      //   {
      //     !item.available &&
      //       <div className="Button-container">
      //         <button className="Dis-btn">Claimed {item.price===0 ? "" : "for $"+item.price}</button>
      //       </div>
      //   }
      //   </div>
