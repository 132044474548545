import { Component } from 'react'
import './Confirm.css';

export default class Confirm extends Component{
  render() {
    var res = this.props.resy;
    if(!this.props.show){
        return null;
    }
    else {
      return(
        <div className="Confirm Window">
          <div className="Confirm Box">
           <div className="Confirm Content">
             <h3>By continuing, you agree to attend this reservation.</h3>
             <p>Would you like to proceed with booking {res.name}?</p>
             <div className="Confirm Actions">
               <button className="Confirm Button" onClick={e => {this.props.onConfirm(e);}}>Yes</button>
               <button className="Confirm Button" onClick={e => {this.props.onCancel(e);}}>No</button>
             </div>
           </div>
         </div>
        </div>
      );
    }
  }
}
