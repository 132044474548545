import './Apply.css';
import { useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

export default function Register() {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(
    {
      first: "",
      last: "",
      zip: "",
      email: "",
      A1:"",
      A2:"",
      A3:"",
      //A4:"",
      Q1: "Duration of Austin residency",
      Q2: "Your top 3 restaurants",
      Q3: "Nights out per week",
      //Q4: "Why do you want to join Peregrine?"
    }
  );
  const [message, setMessage] = useState("");

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://api.peregrine.vip/apply", {
        method: "POST",
        body: JSON.stringify({"input":data,"eid":location.state.eid})
      });
      let resJson = await res.json();
      if (res.status === 200) {
        if (resJson.message === "application submitted"){
          alert("Thank you for applying to Peregrine. Your application has been received.");
          navigate(resJson.redirect);
        }
        else{
          setMessage("API error");
        }
      } else {
        setMessage("API error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (location.state == null){
    return(
      <Navigate to="/" replace={true} />
    );
  }
  else{
    if ("eid" in location.state){
      return (
          <div>
            <div className="Module Heading-module">
              <br></br>
              <h4>You needn't append your name to the bottom of a weeks-long waitlist merely to enjoy a good meal.</h4>
              <br></br>
              <h3>apply to join</h3>
              <h1>P E R E G R I N E</h1>
              <br></br>
              <br></br>
              <h4>Our members receive a digest of tables available <strong>this week</strong> at Austin's most exclusive restaurants.</h4>
              <br></br>
            </div>
            <div className="Module Form-module">
              <form onSubmit={handleSubmit}>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder="First name"
                    type="text"
                    value={data.first}
                    name="first"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder="Last name"
                    type="text"
                    value={data.last}
                    name="last"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder="Email address"
                    type="text"
                    value={data.email}
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder="Zipcode"
                    type="text"
                    value={data.zip}
                    name="zip"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder={data.Q1}
                    type="text"
                    value={data.A1}
                    name="A1"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder={data.Q2}
                    type="text"
                    value={data.A2}
                    name="A2"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="Textbox-container">
                  <input
                    className="Textbox"
                    placeholder={data.Q3}
                    type="text"
                    value={data.A3}
                    name="A3"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/*
                <div className="Longtext-container">
                  <textarea
                    className="Longtext"
                    placeholder={data.Q4}
                    value={data.A4}
                    name="A4"
                    onChange={handleChange}
                    required
                  />
                </div>
                */}
                <div className="Button-container"><button className="Apply-btn">SUBMIT APPLICATION</button></div>
                <p><span className="Filler">-</span>{message}</p>
              </form>
            </div>
          </div>
      );
    }
    else{
        return(
          <Navigate to="/" replace={true} />
        );
    }
  }
}
